import {userLogo} from "../../../assets/Consts"
import HeaderLine from "./HeaderLine"
import UserInfromationBox from "./UserInformationBox";
import SessionTimer from "./SessionTimer";
import { useAuth } from "react-oidc-context";
import HorizontalBar from "../common/HorizontalBar";
import classes from "./Header.module.css"
import { ApplicationState, isOrderingAreaState, setApplicationState } from "../../../store/ApplicationSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../store/ReduxStore";
import { useMemo } from "react";
import { Action, CombinedState, ThunkDispatch } from "@reduxjs/toolkit";
import { RESET_WORKFLOW } from "../../../store/CommonActions";
import { goToDashboard } from "../../../store/ApplicationSliceActions";

const Header = ({applicationState}: { applicationState: ApplicationState }) => {
    const auth = useAuth();
    const isAdminArea = !isOrderingAreaState(applicationState)
    const dispatch = useDispatch<AppDispatch>();

    const tabs = useMemo(() => getTabs(isAdminArea), [isAdminArea])

    const clickAction = (isAuthenticated: boolean, tab: Tab) => {
        if (isAuthenticated) {
            if (tab.action !== undefined) {
                tab.action(dispatch)
            } else if (tab.applicationState !== undefined) {
                dispatch(setApplicationState(tab.applicationState))
            }
        }
    }

    return (
    <>
        <HeaderLine className={`${classes.topHeader}`}>
            <div>
                <img src={`${process.env.PUBLIC_URL}/T_logo_claim_rgb_n.svg`} alt="Telekom logo" style={{width: "138px"}}/>
            </div>
            <div className="teleNeo24Bold">5G Live Video {getHeaderText(isAdminArea)}</div>
        </HeaderLine>
        <header style={{background: "var(--telekom-color-primary-standard)"}} className="second-header">
            <HorizontalBar style={{borderTopLeftRadius: 16, borderTopRightRadius: 16, backgroundColor: "white", height: 60}}>
                <div className={`${classes.tabs}`}>
                    {
                        auth.isAuthenticated ?
                            tabs.map(tab => 
                                <div className="title-text" key={tab.tabName}>
                                    <h5 className={`${applicationState === tab.applicationState ? "selected" : ""}`} onClick={() => clickAction(auth.isAuthenticated, tab)}>{tab.tabName}</h5>
                                </div>
                            )
                            : null
                    }
                </div>
                { auth.isAuthenticated &&
                    <div className="dropstart">
                        <div>
                            <div className="dropdown">
                                <button className="btn-primary-outline dropdown-toggle user-button" id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={userLogo} className="user-icon" alt="user" />{auth.user?.profile.name}
                                </button>
                                <ul className="dropdown-menu user-info-box dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                    <UserInfromationBox isAdminArea={isAdminArea} />
                                </ul>
                            </div>
                        </div>
                        <SessionTimer></SessionTimer>
                    </div>
                }
            </HorizontalBar>
        </header>
    </>
  )
}

export default Header

type Tab = {
    tabName: string;
    applicationState?: ApplicationState;
    action?: (dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => void
}

const getTabs = (isAdminArea: boolean): Tab[] => isAdminArea ? [{
        tabName: "SIM Karten",
        applicationState: "SIMCARDS_TAB"
    }, {
        tabName: "Bestellungen",
        applicationState: "ORDERS_TAB"
    }, {
        tabName: "Kundenprofil",
        applicationState: "CUSTOMERS_TAB"
    }, {
        tabName: "Buchungskonfiguration",
        applicationState: "CONFIGURATION_TAB"
    }] : [{
        tabName: "Live Video Production",
        applicationState: "PRODUCT_SELECTION_SCREEN",
        action: (dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => dispatch({type: RESET_WORKFLOW})
    }, {
        tabName: "Dashboard",
        applicationState: "DASHBOARD_SCREEN",
        action: (dispatch: ThunkDispatch<CombinedState<RootState>, undefined, Action>) => dispatch(goToDashboard())
    }, {
        tabName: "Weitere Infos",
        action: () => window.open("https://developer.telekom.com/de/produkte/5G-Live-Video-Production", "_blank")
    }]

const getHeaderText = (isAdminArea: boolean): string => {
    if (isAdminArea) {
        return "Admin Portal";
    }
    return "Production";
}