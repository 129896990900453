import { useEffect, useState } from 'react';
import { getVersion } from '../../api/backend/applicationApi';
import HorizontalBar from './common/HorizontalBar'
import { useAuth } from 'react-oidc-context';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/ReduxStore';
import { isFromDtSelector } from '../../store/UserInfoSlice';
import { getPrivacyUrl } from '../../App';
import { Link } from 'react-router-dom';

const Footer = () => {
  const [buildVersion, setBuildVesrion] = useState('');
  const {isAuthenticated, user} = useAuth();
  const isFromDt = useSelector<RootState, boolean>(isFromDtSelector);

  useEffect(() => {
    if (isAuthenticated && user?.access_token) {
      getVersion()
          .then(res => {
            if (res.buildVersion) {
                const commitId = res.git?.commit?.id
                console.log(`Build version of backend: ${res.buildVersion} ${commitId}`);
                setBuildVesrion(`${res.buildVersion}${commitId ? ` ${commitId}` : ""}`);
            }
          })
          .catch((e) => {
            console.error("Fetch version error " + e?.status);
          }
      );
    } else {
      setBuildVesrion("");
    }
  }, [isAuthenticated, user]);

  return (
    <footer className="footer">
        <HorizontalBar style={{height: "84px"}}>
            <div style={{display: "flex", gap: "5px", flexDirection: "column", marginTop: "5px"}}>
              <div><img src={`${process.env.PUBLIC_URL}/T_logo_claim_rgb_n.svg`} alt="Telekom logo" style={{width: "138px"}}/></div>
              <div>© Deutsche Telekom GmbH</div>
            </div>
            <div className="text-center" style={{display: "flex", gap: "5px", alignItems: "center"}}>
              <a href={getPrivacyUrl(isFromDt)} className="link" rel="noreferrer" target="_blank">
                <img style={{height:"20px", width:"20px"}} src={`${process.env.PUBLIC_URL}/data-privacy_graphical.svg`} alt="Data Privacy" /> <div style={{alignContent: "center"}}>Datenschutzhinweise</div>
              </a>
              <Link to="/impressum" className="link">
                Impressum
              </Link>
            </div>
            <div style={{display: "flex", gap: "10px", justifySelf: "end"}}>
              <div style={{alignContent: "center", display: "flex", gap: "5px"}}>Version: {printVersion("frontend", process.env.REACT_APP_VERSION)} {printVersion("backend", buildVersion)}</div>
            </div>
        </HorizontalBar>
    </footer>
  )
}

const printVersion = (type: string, version?: string) => version ? <div>{version} ({type})</div>: ""

export default Footer