import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Provider } from 'react-redux';
import { store } from './store/ReduxStore';
import '@telekom/scale-components/dist/scale-components/scale-components.css';
import './i18n';
import {
    applyPolyfills,
    defineCustomElements,
} from '@telekom/scale-components/loader';
import Notification from "./components/ui/common/message/Notification";
import App from "./App";
import { AuthProvider, AuthProviderProps } from 'react-oidc-context';
import { WebStorageStateStore } from 'oidc-client-ts';
import { getOIDCAuthority, getOIDCClientId, getOIDCRedirectUrl } from './utils/env';
import { getUserInfo } from './api/backend/applicationApi';
import { setUserInfo } from "./store/UserInfoSlice";
import { getProductList } from './api/backend/productApi';
import { setProductList } from './store/workflow/ProductSlice';
import { getCustomers } from './api/backend/simCardsApi';
import { setSimCardsListRelatedParties } from './store/workflow/SimCardsSlice';

const el = document.getElementById('root')
if (el === null) throw new Error('Root container missing in index.html')

applyPolyfills().then(() => {
    defineCustomElements(window);
});

const oidcConfig: AuthProviderProps = {
    authority: getOIDCAuthority(),
    client_id: getOIDCClientId(),
    redirect_uri: getOIDCRedirectUrl(),
    userStore: new WebStorageStateStore({store: window.localStorage}),
    iframeScriptOrigin: "window.location.origin",
    onSigninCallback: async () => {
        store.dispatch(setUserInfo(await getUserInfo()))
        getProductAction()
        getSimCardsRelatedPartiesAction()
    }
};

const root = ReactDOM.createRoot(el)
root.render(
    <React.StrictMode>
        <AuthProvider {...oidcConfig}>
            <Provider store={store}>
                <App/>
                <Notification/>
            </Provider>
        </AuthProvider>
    </React.StrictMode>
);

const getProductAction = async () => {
    try {
        const products = await getProductList();
        store.dispatch(setProductList(products));
    } catch (e) {
        console.error("Error in reading products");
    }
}

const getSimCardsRelatedPartiesAction = async () => {
    try {
        const customers = await getCustomers();
        store.dispatch(setSimCardsListRelatedParties(customers));
    } catch (e) {
        console.error("Error in reading simcards");
    }
}