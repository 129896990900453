import {useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { setPositionAndAddress } from '../store/workflow/MapSlice';
import { Point } from '../store/workflow/models';
import { AppDispatch, useAppSelector } from '../store/ReduxStore';

export const useInitOrSavedPosition = () => {
    const dispatch = useDispatch<AppDispatch>();
    const position = useAppSelector(state => state.mapReducer.position)

    const initPosition: Point = {
        lat: 52.517037,
        lng: 13.38886
    };

    useEffect(() => {
        if (position === undefined) {
            dispatch(setPositionAndAddress(initPosition))
        }
    }, [position, dispatch]);

    return position;
};
