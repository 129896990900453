import { ApplicationState } from '../../store/ApplicationSlice';
import DashboardPage from '../dashboard/DashboardPage';
import BookingWorkflowPage from './BookingWorkflowPage';
import ProductSelectionPage from './product/ProductSelectionPage';

const BookingArea = ({applicationState}: { applicationState: ApplicationState }) => {
  
    return (
        <>
            <DashboardPage applicationState={applicationState} />
            <ProductSelectionPage applicationState={applicationState} />
            <BookingWorkflowPage applicationState={applicationState} />
        </>
    )
}

export default BookingArea