import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState } from "./ReduxStore";

export type Role = 'DTADMIN' | 'DTSERVICEAGENT' | 'CUSTOMERADMIN' | 'CUSTOMERUSER';

export interface UserInfo {
    role?: Role;
}

const userInfoInitialState: UserInfo = {
    role: undefined
};

export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState: userInfoInitialState,
    reducers: {
        setUserInfo: (_state, action: PayloadAction<UserInfo>) => ({
            role: action.payload.role
        }),
        clearUserInfo: () => ( userInfoInitialState )
    }
});

export const isFromDtSelector = createSelector(
    (rootState: RootState) => rootState.userInfoSliceReducer,
    (userInfoState) => {
        const { role } = userInfoState;
        return role === 'DTADMIN' || role === 'DTSERVICEAGENT'
    }
)

export const { setUserInfo, clearUserInfo } = userInfoSlice.actions;
export const userInfoSliceReducer = userInfoSlice.reducer;