import { ScaleLoadingSpinner } from '@telekom/scale-components-react';
import Map from '../../components/map/MapComponent';
import SendOrderComponent from '../../components/sections/sendOrder/SendOrderComponent';
import LeftPanel from '../../components/ui/layout/LeftPanel';
import { useInitOrSavedPosition } from '../../hooks/UseCurrentOrSavedPosition';
import { ApplicationState, isWorkflowState } from '../../store/ApplicationSlice';

const BookingWorkflowPage = ({applicationState}: { applicationState: ApplicationState }) => {
    const showWorkflow = isWorkflowState(applicationState);
    const center = showWorkflow ? useInitOrSavedPosition() : undefined;

  
    return (
    showWorkflow ?
      <div style={{display: "flex", flexDirection: "row"}} className="workflow">

        {
          center !== undefined ? <>
            <SendOrderComponent applicationState={applicationState} />
            <LeftPanel />
            <Map initPosition={center} applicationState={applicationState} />
          </> : <div style={{display: "flex", alignItems: "center", width: "100%", justifyContent: "center"}}>
            <ScaleLoadingSpinner alignment="vertical" text='Loading coordinates'/>
          </div>
        }
      </div>
    : null
    )
}

export default BookingWorkflowPage