import classes from "./ProductCard.module.css";
import {ScaleButton, ScaleCard, ScaleIconActionSuccess, ScaleIconDeviceCamera} from "@telekom/scale-components-react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store/ReduxStore";
import {setApplicationState} from "../../store/ApplicationSlice";
import {selectProductIndex} from "../../store/workflow/ProductSlice";
import moment from "moment-timezone";
import {ProductDTO} from "../../store/workflow/models";
import {ProductIcons} from "../../pages/booking/product/ProductIcon";
import { initSimcards } from "../../store/workflow/SimCardsSlice";

const ProductCard = ({product, nameCard, infoPoints, hidePrice, index} :
                         {product: ProductDTO, nameCard: string, infoPoints: string[], hidePrice: boolean, index: number}) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <ScaleCard className={`${classes.card}`}>
            <div className={`${classes.contentSlot}`}>
                <div className={`${classes.eyebrow}`}>
                    {product.icon ? ProductIcons[product.icon] : <ScaleIconDeviceCamera/>}<span className={`teleNeo16`}> {nameCard}</span>
                </div>
                <div className={`teleNeo32Bold ${classes.description}`} style={{fontFamily: 'TeleNeo'}}>{product.name || "undefined"}</div>
                <div className={`${classes.description}`}>
                    <div className={`teleNeo16`}>Leistungen</div>
                    {
                        infoPoints.map((info, index) => {
                        return (
                            <div key={index} className={`${classes.iconWithText}`}><ScaleIconActionSuccess color="#E20074"/>
                                <div className={`${classes.textBody}`}>{info}</div>
                            </div>
                        )}
                        )
                    }
                </div>
            </div>
            <div style={{display: "ruby"}}>
                <div className={`teleNeo32 ${classes.price}`} style={ hidePrice ?{visibility:"hidden"}:{visibility:"initial"}}>{product.price} €</div>
                <div className={`teleNeo16Light ${classes.price}`} style={ hidePrice ?{visibility:"hidden"}:{visibility:"initial"}}> / {moment.duration(product?.lengthBillingUnit?.value).asMinutes()} Minuten</div>
            </div>
            {
                hidePrice
                ? <ScaleButton className={`teleNeo16BoldLight ${classes.askButton}`} disabled>Verfügbarkeit abfragen & buchen</ScaleButton>
                : <ScaleButton className={`teleNeo16BoldLight ${classes.askButton}`} onClick={() => {
                    dispatch(setApplicationState("LEFT_PANEL_HIDDEN"));
                    dispatch(selectProductIndex(index))
                    dispatch(initSimcards())
                }}>Verfügbarkeit abfragen & buchen</ScaleButton>

            }
        </ScaleCard>
    )
}

export default ProductCard