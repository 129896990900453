import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOrders } from "../api/backend/userOrdersApi";
import { UserOrder } from "./workflow/models";
import { setUserOrdersList } from "./UserOrdersSlice";
import { setApplicationState } from "./ApplicationSlice";

export const goToDashboard = createAsyncThunk<void, void>('application/goToDashboard',
  async (_, { dispatch }) => {
  let userOrders;
  try {
    userOrders = await getOrders();
  } catch (error) {
    console.error("Error in reading orders");
    userOrders = [] as UserOrder[];
  }
  dispatch(setUserOrdersList(userOrders));
  dispatch(setApplicationState("DASHBOARD_SCREEN"));
})