import { ApplicationState } from '../../store/ApplicationSlice';

const AdminArea = ({applicationState}: { applicationState: ApplicationState }) => {
  
    console.log(applicationState)
    return (
        <>
        </>
    )
}

export default AdminArea